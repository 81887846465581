import React from "react";
import Layout from "../components/layout-2";
import SEO from "../components/seo";
import MenuDrawer from "../components/atoms/menuDrawer";

function ContactPage() {

  return (
    <Layout>
      <SEO
        keywords={[
          `épület`,
          `enteriőr`,
          `ingatlan`,
          `iroda`,
          `lakás`,
          `építészetií`,
          `fotó`,
          `fotós`,
          `fotográfus`,
        ]}
        title="Válasz"
      />
      <section style={{height: `calc(100vh - 224px)`}} className="flex flex-row justify-center px-4 mt-12 md:mt-16">
        <div className="flex flex-col justify-center w-full">
        <h1 className="mb-6 text-4xl font-black leading-tight text-center text-black md:mb-6 lg:mb-10 xl:mb-12 2xl:mb-16 md:text-5xl lg:text-5xl xl:text-5xl 2xl:text-6xl 3xl:text-7xl">
            Az üzenetet sikeresen elküldted!  
        </h1>
        <p className="text-sm font-normal leading-6 text-center text-black md:leading-9 md:text-xl xl:leading-8 lg:leading-8 lg:text-xl 2xl:leading-10 xl:text-lg 2xl:text-xl">
          Várhatóan 24-órán belül válasszolok.<br/>Köszönöm türelmed!
        </p>
        </div>
      </section>
      <MenuDrawer/>
    </Layout>
  );
}

export default ContactPage;
